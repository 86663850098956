@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none;
    color: rgb(202, 202, 202) !important;
    font-family: 'Poppins', sans-serif !important;

}

body {
    background: #252525;
}


.loading-container {
    // position in top right corner
    position: absolute;
    top: 0;
    right: 0;
    // transform, make it smaller and move it to the right and up
    transform: scale(0.25) translate(90%, -90%);
}


.login-container {
    width: 400px;
    height: 500px;

    display: flex;
    flex-direction: column;

    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .inner-container {
        display: flex;
        flex-direction: column;
        margin: inherit;
        position: inherit;
        top: inherit;
        left: inherit;
        transform: inherit;
        width: inherit;

        .login-button {
            margin-top: 1em;
        }
    }
}

.main-container {
    // center the main container
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .name {
      margin-bottom: 2em;
    }
    
    .announcement-textfield {
      width: 20em;
      height: 1em !important;
      margin-top: 1em;
    }

}

.logout-container {
  // position on bottom of page, in the middle
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  button {
    margin-bottom: 1em;
  }
  
}



.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 30%;
    background: #fff;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  